import React,  { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import {Row, Col, Modal, InputGroup, Form, Accordion, Container, Button, Card, ListGroup, ListGroupItem} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import RendConf from './RendConf';
library.add(fas)

export default function VevoEmailMegadas(props) {  
  const [elfogadta, setElfogadta] = useState(true);
  const [form_emailcim_validated, setForm_emailcim_validated] = useState(false);
  const [vevoEmail_in_form, setVevoEmail_in_form] = useState(undefined);
  const [vevoEmail_sikeresen_megadva, setVevoEmail_sikeresen_megadva] = useState(false);
  const [vevoEmail_kuldes_hibauzenet, setVevoEmail_kuldes_hibauzenet] = useState('');
  const [gombLathato, setGombLathato] = useState(true)

  useEffect(() => {
    
  }, []);

  function onChange_vevoEmail(email){
      setVevoEmail_in_form(email)
  }

  function onClick_emailcim_megadasa(event){
    event.preventDefault();
    event.stopPropagation();
    if (props.getCommInProgress()){ return; }
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
        // rossz emailcím
        setForm_emailcim_validated(true);
        return;
    }
        
    var data = {
        vevoEmail: vevoEmail_in_form
    };

    // nem ellenőrizzük, hogy mi van vele, csak küldünk egy emailt, amivel folytathatja
    setGombLathato(false);
    
    props.callAPI("General.checkEmailAndCreateVevoAndSendLoginMessage", data, function(resp){
        setVevoEmail_sikeresen_megadva(resp.data.sendResult == 'ok');
        setVevoEmail_kuldes_hibauzenet(resp.data.sendResult);
        props.onComplete()
    });    
    
  }

  function onChange_elfogadta(e){
    setElfogadta(e.currentTarget.checked);
  }

  return (
    <div className="vevoEmailMegadas">
    {(!vevoEmail_sikeresen_megadva)
      ? 
      <div>
        {gombLathato ? 
          <div>
            <div className="utasitas-megjegyzes">&nbsp;</div>
            <div className="utasitas">A regisztrációhoz adja meg email-címét!</div>
            <div className="utasitas-megjegyzes">Rendszerünk üzenetet fog küldeni Önnek. Az üzenetben található link segítségével végezheti el a regisztrációt.</div>
            
            {vevoEmail_kuldes_hibauzenet != '' ?
              <div>{vevoEmail_kuldes_hibauzenet}</div>
              :
              undefined
            }
            <br />
            <Form noValidate validated={form_emailcim_validated} onSubmit={onClick_emailcim_megadasa.bind(this)} >
                <Form.Group className="mb-3" controlId="formFelnott_nev">
                    <InputGroup hasValidation={true}>
                        <InputGroup.Text id="inputGroupPrepend"><FontAwesomeIcon icon="fa-envelope" size='2x' color="#609090" /></InputGroup.Text>
                        <Form.Control 
                            required
                            type="email"
                            placeholder="E-mail"
                            value={vevoEmail_in_form || ''}
                            onChange={e => onChange_vevoEmail(e.target.value)}
                            />
                        
                        <Form.Control.Feedback type="invalid">Adjon meg érvényes email-címet!</Form.Control.Feedback>
                    </InputGroup>
                    {/*
                    <div className="noselect">A továbblépéshez el kell fogadnia Alapítványunk&nbsp;
                        <a target="_blank" href={RendConf.szabalyzatURL} >adatvédelmi szabályzatát</a>!
                        <br /><br />
                        <div className="elfogado_szoveg"><Form.Check inline id="sasa" onChange={onChange_elfogadta} type="checkbox" label="Az adatvédelmi szabályzatot elfogadom" value={elfogadta} /></div>
                        
                      </div>
                      */}
                      <br />
                      <Button disabled={!elfogadta} variant={elfogadta ? "primary" : 'default'} type="submit">Mehet</Button>
                </Form.Group>

            </Form>
          </div>
          :
          <div className="kerjuk_varjon">          Kérjük, várjon!        </div>
        }
        
      </div>
    :
      <div>
        Köszönjük, hogy regisztrációt kezdeményezett egy meghirdetett rendezvényünkre!
        <br /><br />
        Rendszerünk a megadott email-címre üzenetet küldött,<br />
        az üzenetben található link segítségével végezheti el a regisztrációt.<br />
        <br />
        Ezt az ablakot most bezárhatja.
      </div>
    }
  </div>
  )
}