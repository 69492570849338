import React,  { useState } from 'react';
import {Modal, Form, Button} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

export default function ModalReszletesInfo({rendezveny, onSave, onHide, ...otherProps}) {
    function onClick_hide(){
        onHide()
    }

    return (
        <Modal  onHide={onHide} {...otherProps} size="lg" aria-labelledby="contained-modal-title-vcenter" centered >
            {/*onEntered={onShow.bind(this)}*/}
        <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
                {(rendezveny != undefined) ? rendezveny.Cim : undefined}
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div><i>{(rendezveny != undefined) ? rendezveny.Alcim : undefined}</i></div>
            <hr />
            {rendezveny != undefined ?
                <div dangerouslySetInnerHTML={{__html: rendezveny.Infohtmlhosszu}} ></div>
                : undefined}
        </Modal.Body>
        <Modal.Footer>
            <Button variant="default" onClick={onClick_hide}>Bezár</Button>
        </Modal.Footer>
        </Modal>            
        
    )
}
