import React,  { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate} from 'react-router-dom';
import {Row, Col, Modal, InputGroup, Form, Accordion, Container, Button, Card, ListGroup, ListGroupItem} from 'react-bootstrap';

import 'bootstrap/dist/css/bootstrap.min.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
library.add(fas)

export default function JegyLista({jegyek, onSave, rendezveny, ...props}) {
    function getJegyTipusOfRendezveny(jegyTipusID){
        for (var jegyTipus of rendezveny.jegytipusok){
            if (jegyTipus.Id == jegyTipusID){
                return jegyTipus;
            }
        }
        return undefined;
    }

    function getOsszesenAr(){
        var osszAr = 0;
        for (var egyJegy of jegyek){
            osszAr += getJegyTipusOfRendezveny(egyJegy.jegyTipus.Id).Arnetto;
        }
        return osszAr;
    }

    function onClick_btn(jegyTipusID, direction){
        var ujJegyek = [];
        if (direction == 'up'){
            // egy új jegy
            for (var i in jegyek){
                ujJegyek.push(jegyek[i]);
            }
            var ujJegy = {
                Vendegneve: "", // A személy neve 
                jegyTipus: getJegyTipusOfRendezveny(jegyTipusID),
                rendezvenyReszek: []
            }
            ujJegyek.push(ujJegy);
        }else{
            // egy jegy törlése
            let toroltuk_mar = false;
            for (var i in jegyek){
                if ((jegyek[i].jegyTipus.Id == jegyTipusID) && (!toroltuk_mar)){
                    toroltuk_mar = true;
                }else{
                    ujJegyek.push(jegyek[i]);
                }
            }
        }
        onSave(ujJegyek);
    }

    function getJegytipusDarabszam(jegytipusID){
        var osszDb = 0;
        for (var egyJegy of jegyek){
            if (egyJegy.jegyTipus.Id == jegytipusID){
                osszDb++;
            }
        }
        return osszDb;     
    }

    function getOsszesenArEgyJegyTipusra(jegytipusID){
        var osszAr = 0;
        for (var egyJegy of jegyek){
            if (jegytipusID == egyJegy.jegyTipus.Id){
                osszAr += getJegyTipusOfRendezveny(egyJegy.jegyTipus.Id).Arnetto;
            }
        }
        return osszAr;
    }

    if (rendezveny == undefined){
        return <div></div>
    }else{
        return (
            <div className="jegylista">
                {rendezveny.jegytipusok.map(function(jegyTipus){
                    return (
                        <div className="jegy" key={"jt_" + jegyTipus.Id}>

                            <div className="cimbox">
                                <div className="cim">{jegyTipus.Nev}</div>
                                <div className="ar">{jegyTipus.Arnetto} Ft</div>
                            </div>
                            <div className="darabbox">
                                    <div className="plusminusbtn down" onClick={onClick_btn.bind(this, jegyTipus.Id, 'down')}>
                                        <FontAwesomeIcon icon="fa-circle-minus" size='3x' color="darkcyan" />
                                    </div>
                                    
                                <div className="szam noselect">{getJegytipusDarabszam(jegyTipus.Id)}</div>
                                    
                                <div className="db noselect">db</div>
                                    <div className="plusminusbtn up" onClick={onClick_btn.bind(this, jegyTipus.Id, 'up')}>
                                        <FontAwesomeIcon icon="fa-circle-plus" size='3x' color="darkcyan" />
                                    </div>
                                    
                            </div>
                            <div className="arbox">
                                <div className="szam noselect">{getOsszesenArEgyJegyTipusra(jegyTipus.Id)}</div>
                                <div className="ft noselect">Ft</div>
                            </div>

                        </div>    
                    )
                })}

                <div className="osszesen noSelect">
                    <div className="ft noselect">Ft</div>
                    <div className="szam noselect">{getOsszesenAr()}</div>
                    <div className="osszesenFelirat noselect">Összesen:</div>
                    
                </div>
                <div className="osszesenFeliratMegjegyzes">A jegyek értékesítését végző alapítvány alanyi adómentes státusza miatt<br />
                az ár nem tartalmaz általános forgalmi adót.</div>
            </div>
        )
    }
}
