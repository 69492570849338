import React,  { useEffect, useState, useRef } from 'react';

import { useLocation, useNavigate} from 'react-router-dom';
import {Row, Col, Modal, InputGroup, Form, Accordion, Container, Button, Card, ListGroup, ListGroupItem} from 'react-bootstrap';
import ModalSzamlazasiAdatok from './modals/ModalSzamlazasiAdatok';

import 'bootstrap/dist/css/bootstrap.min.css';
import ModalVendeg from './modals/ModalVendeg';

import StripeApp from '../../../StripeApp'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
library.add(fas)




export default function OnlineFizetesStripe({callAPI, megrendelesID, ...props}) {
    const [buttonDisabled, setButtonDisabled] = useState(false);

    function onClick(){
        //setButtonDisabled(true);
        callAPI("General.getStripeURL", {megrendelesID: megrendelesID}, function(resp){
            console.log("success: " + resp.data.stripeURL)
            window.location = resp.data.stripeURL
        });
    }
    return (
        <div className="onlineFizetes">
            <Button disabled={buttonDisabled} onClick={onClick}>Stripe fizetés</Button>
        </div>
    )
}
