import React,  { useState, useEffect } from 'react';
import {Modal, Form, Button, ButtonGroup, ToggleButton} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

export default function ModalSzamlazasiAdatok({vevo, onSave, onHide, ...props}) {
    let me = this;

    const [szemelyNev, setSzemelyNev] = useState('');
    const [email, setEmail] = useState('');
    const [ceg, setCeg] = useState(false);
    const [cegNev, setCegNev] = useState('');
    const [telepules, setTelepules] = useState('');
    const [irsz, setIrsz] = useState('');
    const [cim, setCim] = useState('');
    const [adoszam, setAdoszam] = useState('');

    useEffect(() => {

    }, [ceg]);

    function onSave_inside(){
        var aVevo = {
            Szemelynev: szemelyNev,
            Ceg: ceg,
            Cegnev: cegNev,
            Telepules: telepules,
            Irsz: irsz,
            Cim: cim,
            Adoszam: adoszam,
            Email: email
        };

        props.callAPI("General.updateVevoByEmail", {vevo: aVevo}, function(resp){
            onSave(resp.data.vevo);
        });
    }

    function onClick_hide(){
        onHide()
    }

    function onShow(){
        setSzemelyNev(vevo.Szemelynev)
        setEmail(vevo.Email)
        setCeg(vevo.Ceg)
        setCegNev(vevo.Cegnev)
        setTelepules(vevo.Telepules)
        setIrsz(vevo.Irsz)
        setCim(vevo.Cim)
        setAdoszam(vevo.Adoszam)
    }

    function onSubmitPreventer(event){ event.preventDefault()};

    function onChange_ceg(val){
        console.log(val);
        setCeg(val)
        /*
        if (obj[1] == 1){
            setCeg(true)
            console.log("ceg tru")
        }else{
            setCeg(false)
            console.log("cegfalse ")
        }
        */
    }

    return(
        <Modal dialogClassName='modalSzamlazasiAdatok' contentClassName='contentSzamlazasiAdatok' onEntered = {onShow.bind(this)}
        onHide={onHide} onShow={onShow} {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
            Számlázási adatok
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <p>
                Iskolánk alapítványa számlát állít ki a befizetett összegről.
            </p>
            <Form noValidate onSubmit={onSubmitPreventer.bind(this)} >
                <ButtonGroup>
                    <ToggleButton
                         variant={ceg ? 'outline-success' : 'success'}
                        name="radio" value={false}
                        checked={!ceg} onClick={onChange_ceg.bind(this, false)}
                    >Magánszemély</ToggleButton>
                    <ToggleButton
                         variant={ceg ? 'success' : 'outline-success'}
                        name="radio" value={true}
                        checked={ceg} onClick={onChange_ceg.bind(this, true)}
                    >Cég</ToggleButton>
                </ButtonGroup>                
                <br /><br />
                {!ceg ? 
                    <Form.Group className="mb-3" controlId="form_nev">
                        <Form.Label>Név</Form.Label>
                        <Form.Control type="text" placeholder="Név" value={szemelyNev} required onChange={e => setSzemelyNev(e.target.value)}/>
                        <Form.Control.Feedback type="invalid">A nevet kötelező megadni.</Form.Control.Feedback>
                        <Form.Text className="text-muted"></Form.Text>
                    </Form.Group>
                    :
                    <div>
                        <Form.Group className="mb-3" controlId="form_nev">
                            <Form.Label>Cégnév</Form.Label>
                            <Form.Control type="text" placeholder="A cég neve" value={cegNev} required onChange={e => setCegNev(e.target.value)}/>
                            <Form.Control.Feedback type="invalid">A cég nevét kötelező megadni.</Form.Control.Feedback>
                            <Form.Text className="text-muted"></Form.Text>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="form_nev">
                            <Form.Label>Adószám</Form.Label>
                            <Form.Control type="text" placeholder="A cég adószáma" value={adoszam} required onChange={e => setAdoszam(e.target.value)}/>
                            <Form.Control.Feedback type="invalid">A cég adószámát kötelező megadni.</Form.Control.Feedback>
                            <Form.Text className="text-muted"></Form.Text>
                        </Form.Group>                        
                    </div>
                }

                
                    <div className="cimTitle">{ceg ? "A cég telephelye" : "Lakcím"}</div>

                    <Form.Group className="mb-3" controlId="form_nev">
                        <Form.Label>Irányítószám</Form.Label>
                        <Form.Control type="text" placeholder="Irányítószám" value={irsz} required onChange={e => setIrsz(e.target.value)}/>
                        <Form.Control.Feedback type="invalid">Az irányítószámot kötelező megadni.</Form.Control.Feedback>
                        <Form.Text className="text-muted"></Form.Text>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="form_nev">
                        <Form.Label>Település</Form.Label>
                        <Form.Control type="text" placeholder="Település" value={telepules} required onChange={e => setTelepules(e.target.value)}/>
                        <Form.Control.Feedback type="invalid">A település nevét kötelező megadni.</Form.Control.Feedback>
                        <Form.Text className="text-muted"></Form.Text>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="form_nev">
                        <Form.Label>Közterület neve, házszám</Form.Label>
                        <Form.Control type="text" placeholder="Közterület, házszám" value={cim} required onChange={e => setCim(e.target.value)}/>
                        <Form.Control.Feedback type="invalid">A közterület nevét és a házszámot kötelező megadni.</Form.Control.Feedback>
                        <Form.Text className="text-muted"></Form.Text>
                    </Form.Group>

            </Form>                    
        </Modal.Body>
        <Modal.Footer>
            <Button onClick={onSave_inside.bind(this)}>Mentés</Button>
            &nbsp;
            <Button variant="default" onClick={onClick_hide}>Mégse</Button>
        </Modal.Footer>
        </Modal>            
    )
}

