import React,  { useEffect, useState, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import ModalReszletesInfo from './ModalReszletesInfo';

import VevoEmailMegadas from './VevoEmailMegadas';
import VevoNevMegadas from './VevoNevMegadas';
import {Row, Col, Modal, InputGroup, Form, Accordion, Container, Button, Card, ListGroup, ListGroupItem} from 'react-bootstrap';
import { Link, BrowserRouter, Route, Routes } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import RendConf from './RendConf';
library.add(fas)



export default function Rendezvenyek({authorizedVevo, onSetNev, onOkSelectedRendezvenyID, ...props}) {  
    const [rendezvenyekByCsoport, setRendezvenyekByCsoport] = useState([]);
    const [rendezvenyForReszletesInfo, setRendezvenyForReszletesInfo] = useState(undefined);
    const [emailMegadvaBezarhatja, setEmailMegadvaBezarhatja] = useState(false);
    

    useEffect(() => {
        props.callAPI("General.getAktualisRendezvenyekByCsoport", undefined, function(resp){
            setRendezvenyekByCsoport(resp.data.rendezvenyekByCsoport);
            console.log(resp.data.rendezvenyekByCsoport);
            /*
                [0:
                    {Id: 1, Cim: "Zenedenap 2022", rendezvenyek: []},
                    {Id: 2. Cim: "Koncertek", rendezvenyek: []}
                ]
            */
        });
    }, []);

    function onSave_vevoNeve(nev){
        onSetNev(nev);
    }

    function onClick_megrendeles_attekintese(rendezveny){
        window.location = rendezveny.reviewURL;
    }

    function onClick_rendezveny(rendezveny, event){
        console.log(rendezveny)
        if (authorizedVevo != undefined && authorizedVevo.Szemelynev != ''){
            onOkSelectedRendezvenyID(rendezveny.Id);
        }
    }
/*
                        <Col>
                            <Card >
                                <Card.Img variant="top" src="img/icon_zenedenap.png" style={{ padding: '10px' }} />
                                <Card.Body style={{ textAlign: 'center' }}>
                                    <Card.Title>Hangszerbemutató, „hangszersimogató”</Card.Title>
                                    <Card.Text>
                                    </Card.Text>
                                    <Link to="/zenedenap"><Button variant="primary">Regisztráció</Button></Link>
                                </Card.Body>
                            </Card>
                        </Col>
*/

    function onClick_rendezveny_reszletes(rendezveny){
        setRendezvenyForReszletesInfo(rendezveny);
    }

    function onHideModal_reszletesInfo(){
        setRendezvenyForReszletesInfo(undefined);
    }

    function onComplete_email_megadasa(){
        setEmailMegadvaBezarhatja(true);
    }

  return (
    <Container>
        {authorizedVevo != undefined ?
            <div className="vevoAdatFejlec">
                <div className="felirat">Ön a következő adatokkal jelentkezett be:</div>
                <div className="adat"><b>{authorizedVevo.Szemelynev}</b>&nbsp;&nbsp;&nbsp;&lt;{authorizedVevo.Email}&gt;</div>
                {authorizedVevo.Szemelynev == '' ?
                    <VevoNevMegadas
                        callAPI={props.callAPI}
                        getCommInProgress={props.getCommInProgress}
                        szemelynev={authorizedVevo.Szemelynev}
                        onSave={onSave_vevoNeve}
                    />
                    : undefined
                }
            </div>
            : undefined
        }
        {!emailMegadvaBezarhatja ?
        <div>                        
            <div className="cim_aktualis_rendezvenyeink">Aktuális rendezvényeink</div>
            {rendezvenyekByCsoport.map(function(csoport){
                return (
                    <div key={'cs_' + csoport.Id}>
                        <div className="rendezvenyCsoportCim">
                            {csoport.Cim}
                        </div>
                        
                        {csoport.rendezvenyek.map(function(rendezveny){
                            let marRegisztralt = false;
                            let marRegisztralt_de_nem_fizetett = false;
                            if (authorizedVevo != undefined){
                                marRegisztralt = authorizedVevo.megrendeltRendezvenyekByID[rendezveny.Id] != undefined;
                                if (marRegisztralt){
                                    marRegisztralt_de_nem_fizetett = authorizedVevo.megrendeltRendezvenyekByID[rendezveny.Id]['Fizetessikeres'] != 1;
                                }
                            }
                            return (
                                <Row className={"rendezvenybox"} key={"rendID_" + rendezveny.Id} >
                                    
                                    <Col className="iconbox" md={2} >
                                        <img className="icon" src={"/img/" + rendezveny.Iconfilename} />
                                    </Col>


                                    <Col className="cimbox" md={8}>
                                        <div className="cim">
                                            {rendezveny['Cim']}
                                        </div>
                                        <div className="alcim">
                                            {rendezveny['Alcim']}
                                        </div>
                                        <div className="rovidInfo" dangerouslySetInnerHTML={{__html: rendezveny.Infohtmlrovid}} />
                                        <div className={"rovidInfo" + ((rendezveny.szabadFerohelyekSzama < 1) ? " nincs-szabad-ferohely" : undefined)}>Szabad férőhelyek száma: {rendezveny.szabadFerohelyekSzama}</div>
                                        <br />
                                        {marRegisztralt ? 
                                        <div>
                                            {marRegisztralt_de_nem_fizetett ? 
                                                <div className="onMarRegisztralt red">
                                                    <div >
                                                        <div className="icon red"><FontAwesomeIcon icon="fa-circle-xmark" size='1x' color="darkred" /></div>
                                                        <div className="text">Ön <b>nem fejezte be</b> regisztrációját erre a rendezvényre!</div>
                                                    </div>
                                                </div>
                                                :
                                                <div className="onMarRegisztralt">
                                                    <div >
                                                        <div className="icon"><FontAwesomeIcon icon="fa-check-circle" size='1x' color="green" /></div>
                                                        <div className="text">Ön már regisztrált erre a rendezvényre!</div>
                                                    </div>
                                                </div>                                            
                                            }
                                        </div>
                                        :
                                        <div></div>
                                        
                                        }
                                        <div className="btnReszletesInfo" onClick={onClick_rendezveny_reszletes.bind(this, rendezveny)}>
                                            <div className="btnInfoIcon"><FontAwesomeIcon icon="fa-info-circle" size='1x' color="#204090" /></div>
                                            <div className="btnInfoText">Részletesebb információkért kattintson ide!</div>
                                        </div>
                                        
                                        
                                    </Col>
                                    
                                        <Col className="regbox" md={2}>
                                            {marRegisztralt ?
                                                <Button
                                                    variant="default"
                                                    style={{width: '100%'}}
                                                    disabled={(authorizedVevo == undefined || authorizedVevo.Szemelynev == '')}
                                                    
                                                    onClick={onClick_megrendeles_attekintese.bind(this, authorizedVevo.megrendeltRendezvenyekByID[rendezveny.Id])}>Áttekintem a regisztrációmat
                                                </Button>
                                                :
                                                <div>
                                                    {rendezveny.szabadFerohelyekSzama > 0 ?
                                                        <div>
                                                            {
                                                                (authorizedVevo != undefined) ?
                                                                    <div>
                                                                        {authorizedVevo.Szemelynev == '' ?
                                                                            <div>Adja meg nevét!</div>
                                                                            :
                                                                            <Button
                                                                                style={{width: '100%'}}
                                                                                variant={(authorizedVevo == undefined || authorizedVevo.Szemelynev == '') ? 'default' : 'primary'}
                                                                                disabled={(authorizedVevo == undefined || authorizedVevo.Szemelynev == '')}
                                                                                onClick={onClick_rendezveny.bind(this, rendezveny)}>{rendezveny.Gombfelirat}
                                                                            </Button>            
                                                                        }
                                                                    </div>
                                                                :
                                                                    <div>A regisztrációhoz adja meg email-címét!</div>
                                                                }
                                                        </div>
                                                        :
                                                        <div>Már nem lehet regisztrálni</div>
                                                    }
                                                </div>
                                            }
                                            
                                        </Col>
                                    
                                </Row>
                                

                            );
                        })}
                    </div>
                )
            })}
        </div>
        : undefined
        }
        {authorizedVevo == undefined ?
            <div className="vevoAdatFejlec">    
                    <VevoEmailMegadas
                        onComplete={onComplete_email_megadasa}
                        callAPI={props.callAPI} getCommInProgress={props.getCommInProgress} />
            </div>
            : undefined
        }
                        <br />
                        <br />

            <ModalReszletesInfo
                onHide={onHideModal_reszletesInfo}
                show={rendezvenyForReszletesInfo != undefined}
                rendezveny={rendezvenyForReszletesInfo}
            />

    </Container>
  )
}