import React,  { useEffect, useState, useRef } from 'react';
import { PayPalButton } from 'react-paypal-button-v2';
import { PayPalScriptProvider, PayPalButtons, BraintreePayPalButtons } from "@paypal/react-paypal-js";

import { useLocation, useNavigate} from 'react-router-dom';
import {Row, Col, Modal, InputGroup, Form, Accordion, Container, Button, Card, ListGroup, ListGroupItem} from 'react-bootstrap';
import ModalSzamlazasiAdatok from './modals/ModalSzamlazasiAdatok';

import 'bootstrap/dist/css/bootstrap.min.css';
import ModalVendeg from './modals/ModalVendeg';

import StripeApp from '../../../StripeApp'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
library.add(fas)

export default function OnlineFizetes({callAPI, szamlazasiAdatok, jegyek, ...props}) {
    const [szamlaURL, setSzamlaURL] = useState(undefined);
    const initialOptions = {
        "client-id": "AWdyCbHJnSwnuR75zWOhxYkJv30vG5Hsf5r75p91a91ol-2xsryXJPfD08yxbwb0ELb8AvkUbtED9zWH",
        currency: "HUF",
        //intent: "capture",
        //"data-client-token": "access_token$production$d6z55yshwh6j3jv3$4c4dc45e16000d5a5dd0d976257ba87a",
        //"data-client-token": "ELbB8LDBQmcQRvIBEdqLtZ7jyjbZHlGOIBi5l-mbwHTMpXcX5NmpVqI-69SxaYN7378uBIWyUQhOcmQv"
        
    };

    function onClick_szamla(){
        var data = {
            szamlazasiAdatok: szamlazasiAdatok,
            jegyek: jegyek
        }
        callAPI("General.createSzamla", data, function(resp){
            setSzamlaURL(resp.data.szamlaURL);
        });
    }
    
    return (
        <div className="onlineFizetes">
            {/* <PayPalScriptProvider options={initialOptions} > */}
            <StripeApp />
                <PayPalButton
                    options = {initialOptions}

                    style = {{
                        shape: 'pill',
                        color: 'blue',
                        layout: 'vertical',
                        label: 'paypal',
                        
                    }}
  
                    createOrder={(data, actions) => {
                        return actions.order.create({
/*
                            return actions.braintree.createPayment({
                                flow: "checkout",
                                amount: "10.0",
                                currency: "USD",
                                intent: "capture",
                            });

*/

                            purchase_units: [{
                                amount: {
                                currency_code: "HUF",
                                value: 2400,
                                breakdown: {
                                    item_total: {  // Required when including the `items` array
                                    currency_code: "HUF",
                                    value: 2400
                                    }
                                }
                                },
                                items: [
                                {
                                    name: "Operastúdió előadás", // Shows within upper-right dropdown during payment approval 
                                    description: "Hajjaj Fekete Vonat", // Item details will also be in the completed paypal.com transaction view 
                                    unit_amount: {
                                    currency_code: "HUF",
                                    value: 1200
                                    },
                                    quantity: 2
                                },
                                ]
                            }],



                        application_context: {
                            shipping_preference: "NO_SHIPPING" // default is "GET_FROM_FILE"
                        }
                        });
                    }}                
                     />
            
            {/*
            <PayPalButton
                
                //shippingPreference='NO_SHIPPING'
                //onButtonReady={() => undefined}
                // createOrder
                
                options={{
                    currency: "HUF",
                    // clientId: "AWdyCbHJnSwnuR75zWOhxYkJv30vG5Hsf5r75p91a91ol-2xsryXJPfD08yxbwb0ELb8AvkUbtED9zWH" // sandbox
                    clientId: "AaPddcNBTsH9CQ9Znxs7H5rVUem7YI7GX5AoJKNoGsGLY0QtEaQfiupkTomLcdJspXPkQqxMBRzrNoPl" // live
                }}
                
                onSuccess={(details, data) => {
                    alert("Transaction completed by " + details.payer.name.given_name);
                }}
            />
*/}

            <Button onClick={onClick_szamla} >Számla kiállítása</Button>
            <br /><br />
            {(szamlaURL != undefined) ?
                <a href={szamlaURL}>Töltsd le a számlát innen!</a>
                : undefined
            }
        </div>
    )
}
