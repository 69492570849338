import React,  { useEffect, useState, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import ModalReszletesInfo from './ModalReszletesInfo';

import { useLocation, useNavigate} from 'react-router-dom';
import VevoEmailMegadas from './VevoEmailMegadas';
import VevoNevMegadas from './VevoNevMegadas';
import {Row, Col, Modal, InputGroup, Form, Accordion, Container, Button, Card, ListGroup, ListGroupItem} from 'react-bootstrap';
import { Link, BrowserRouter, Route, Routes } from 'react-router-dom';
import RendConf from './RendConf';

export default function Review({betoltottMegrendelesData, ...props}) {  
    function onClick_torolni(megrendelesID){
        var answer = window.confirm("Valóban törölni szeretné a regisztrációt?");
        if (answer){
            props.callAPI("General.megrendelesTorleseHaVanEsGetLoginURL", {megrendelesID: megrendelesID}, function(resp){
                alert("Regisztrációját töröltük!")
                window.location = resp.data.loginURL;
            });
        }
    }

    function onClickBack(){
        props.callAPI("General.getLoginURL", {vevoID: betoltottMegrendelesData.vevo.Id}, function(resp){
            window.location = resp.data.loginURL;
        });      
    }

    return(
        <Container>
    <div className="reviewBox">
        <div className="info">
            Az alábbi rendezvény-regisztráció sikeresen megtörtént.<br />
            Az alábbi adatokat emailben elküldtük a megadott email-címre is.<br />
            Az üzenetben egy linket is talál, mellyel később is visszatérhet erre az oldalra!
            <br /><br />
        </div>
        
        <div className="megrendelesHTML" dangerouslySetInnerHTML={{__html: betoltottMegrendelesData.html}} />
        <br />


        {betoltottMegrendelesData.rendezveny.Megrendelestorolheto == 1 ?
            <div className="info">
            <Button variant="danger" onClick={onClick_torolni.bind(this, betoltottMegrendelesData.Id)}>Ha törölni szeretné regisztrációját, kattintson ide!</Button>
            <br /><br />
            </div>
            :
            <div className="info">Ha kérdése van (regisztráció módosítása, törlése stb.), kérje segítségünket az {RendConf.contact_email} címen!
            <br /><br /></div>
        }


        <div className="info nefeledje">
            <b>Ne feledje!</b> Egyéb rendezvényeinkre is várjuk szeretettel! Kattintson az alábbi gombra!
            <br /><br />
            <Button variant="default" onClick={onClickBack}>Vissza a rendezvényekhez</Button>
        </div>

    </div>
    </Container>
    )
}