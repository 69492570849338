import React,  { useEffect, useState, useRef } from 'react';
import {Row, Col, Modal, InputGroup, Form, Accordion, Container, Button, Card, ListGroup, ListGroupItem} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
library.add(fas)


export default function VevoNevMegadas(props) {  
  const [form_nev_validated, setForm_nev_validated] = useState(false);
  const [vevoSzemelynev, setVevoSzemelynev] = useState(false);

  useEffect(() => {
    setVevoSzemelynev(props.szemelynev)
  }, []);


  function onChange_vevoNeve(nev){
    setVevoSzemelynev(nev)
  }

  function onSubmit_nev_megadasa(event){
    console.log("submut nev");
    event.preventDefault();
    event.stopPropagation();
    if (props.getCommInProgress()){
      console.log("comm in progress");
      return;
    }
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
        // rossz név
        console.log("rossz név");
        setForm_nev_validated(true);
        return;
    }
    console.log("on save: " + vevoSzemelynev);
    props.onSave(vevoSzemelynev);
  }

  return (
    <div>
    <br />
    <br />
    <div className="utasitas">Kérjük, adja meg nevét!</div>
    <div className="utasitas-megjegyzes" >(Az Ön kíséretével érkező gyermekek adatait később kell megadnia.)</div>
    <br />
    <Form noValidate validated={form_nev_validated} onSubmit={onSubmit_nev_megadasa.bind(this)}  >
        <Form.Group className="mb-3" controlId="formFelnott_nev">
            <InputGroup hasValidation={true}>
                <InputGroup.Text id="inputGroupPrepend"><FontAwesomeIcon icon="fa-id-card" size='2x' color="#609090" /></InputGroup.Text>
                <Form.Control type="text" placeholder="Név" value={vevoSzemelynev || ''} required onChange={e => onChange_vevoNeve(e.target.value)} />
                <Form.Control.Feedback type="invalid">A nevet kötelező megadni.</Form.Control.Feedback>
            </InputGroup>
            <Form.Text className="text-muted"></Form.Text>
        </Form.Group>
        <Button variant="primary" type="submit">Mentés</Button>
    </Form>                    

    
</div>

    
  )
}