import React,  { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate} from 'react-router-dom';
import {Row, Col, Modal, InputGroup, Form, Accordion, Container, Button, Card, ListGroup, ListGroupItem} from 'react-bootstrap';
import ModalSzamlazasiAdatok from './modals/ModalSzamlazasiAdatok';

import 'bootstrap/dist/css/bootstrap.min.css';
import ModalVendeg from './modals/ModalVendeg';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
library.add(fas)


export default function SzamlazasiAdatokDisplay({vevo, onSave, ...props}) {
    const [modalShown_szamlazasiAdatok, setModalShown_szamlazasiAdatok] = useState(false);

    function onClick_adatok_megadasa(){
        setModalShown_szamlazasiAdatok(true)
    }

    function onSave_szamlazasi_adatok(inTeljesVevo){
        onSave(inTeljesVevo)
        setModalShown_szamlazasiAdatok(false)
    }
    
    function onHide_modal_szamlazasi_adatok(){
        setModalShown_szamlazasiAdatok(false)
    }

    return (
        <div className="szamlazasiAdatokDisplay">
            <div className="cimTitle">Számlázási adatok</div>
            {(vevo != undefined) ? 
                <div className="body">
                    <div className="cegnevSzemelynev">{vevo.Ceg ? vevo.Cegnev : vevo.Szemelynev}</div>
                    <div className="irszTelepules">{vevo.Irsz} {vevo.Telepules}</div>
                    <div className="cim">{vevo.Cim}</div>
                    {vevo.Ceg ?
                        <div className="adoszam">Adószám: <span>{vevo.Adoszam}</span></div>
                        : undefined
                    }
                    <br />
                    <div className="btnEdit" onClick={onClick_adatok_megadasa}>
                        <div className="icon"><FontAwesomeIcon icon="fa-pencil" size='1x' color="#204090" /></div>
                        <div className="text">Adatok szerkesztése</div>
                    </div>

                    {/* <Button onClick={onClick_adatok_megadasa}>Adatok módosítása</Button> */}
                </div>
                :
                <div className="btnEdit" onClick={onClick_adatok_megadasa}>
                    <div className="icon"><FontAwesomeIcon icon="fa-pencil" size='1x' color="#204090" /></div>
                    <div className="text">Adatok szerkesztése</div>
                </div>

                
            }
            <ModalSzamlazasiAdatok
                vevo={vevo}
                show={modalShown_szamlazasiAdatok}
                callAPI={props.callAPI}
                onSave={onSave_szamlazasi_adatok}
                onHide={onHide_modal_szamlazasi_adatok}
            />
        </div>
    )
}
