export default class RendConf{
    static page_title = undefined;

    static load = function(obj){
        RendConf.page_title = obj.page_title
    }

    static inArray = function(needle, haystack) {
        var length = haystack.length;
        for(var i = 0; i < length; i++) {
            if(haystack[i] == needle) return true;
        }
        return false;
    }
}