import React,  { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate} from 'react-router-dom';
import {Row, Col, Modal, InputGroup, Form, Accordion, Container, Button, Card, ListGroup, ListGroupItem} from 'react-bootstrap';

import 'bootstrap/dist/css/bootstrap.min.css';
import ModalVendeg from './modals/ModalVendeg';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
library.add(fas)

export default function VendegLista({rendezveny, jegyek, ...props}) {
    const [szerkesztheto, setSzerkesztheto] = useState(true);
    const [vendegIndexFake, setVendegIndexFake] = useState(0);  // a vendégeknek addig is kell egy azonosító, ameddig a szerver nem rögzítette őket
    const [modalShown_vendeg, setModalShown_vendeg] = useState(false);
    //const [modalShown_reszek, setModalShown_reszek] = useState(false);
    //const VENDEGTIPUS_GYEREK_HANGSZERKIPROBALO = "gyerek"
    //const VENDEGTIPUS_FELNOTT_KISERO = "felnott"
    const [modalVendegJegyTipus, setModalVendegJegyTipus] = useState({});
    //const [kivalasztottVendegReszekBeallitasahoz, setKivalasztottVendegReszekBeallitasahoz] = useState(undefined);
    


    function onClick_jegy_hozzaadasa(jegyTipus){
        setModalVendegJegyTipus(jegyTipus);
        setModalShown_vendeg(true);
    }

    function hideModal_vendeg(){
        setModalShown_vendeg(false)
    }

    function saveModal_vendeg(jegyData){
        setVendegIndexFake(vendegIndexFake + 1);
        jegyData.fakeIndex = vendegIndexFake;

        var ujJegyek = [];
        for (var i in jegyek){
            ujJegyek.push(jegyek[i]);
        }
        ujJegyek.push(jegyData);
        props.onSave(ujJegyek);
        hideModal_vendeg()
    }

    function onClick_jegy_torlese(torlendoJegy){
        var ujJegyek = [];
        for (var egyJegy of jegyek){
            if (torlendoJegy != egyJegy){
                ujJegyek.push(egyJegy);
            }
        }
        props.onSave(ujJegyek);
    }

    function elerte_a_maximumot_a_jegyek_szama_ebben_a_jegytipusban(jegyTipus){
        if (jegyTipus.Maxjegytipusegymegrendelesben == undefined){
            return false;
        }
        var hany_ilyen_tipusu_van = 0;
        for (var jegy of jegyek){
            if (jegy.jegyTipus.Id == jegyTipus.Id){
                hany_ilyen_tipusu_van++;
            }
        }
        let elerte = hany_ilyen_tipusu_van >= jegyTipus.Maxjegytipusegymegrendelesben;
        return elerte;
    }



    return (
        <div>
            <ModalVendeg
                onHide={hideModal_vendeg}
                jegyTipus={modalVendegJegyTipus}
                show={modalShown_vendeg}
                onSave={saveModal_vendeg.bind(this)}
                rendezveny={rendezveny}
            />
            {/*
            <ModalRendezvenyReszValaszto
                onHide={() => hideModal_reszek()}
                show={modalShown_reszek} onSave={saveModal_reszek.bind(this)}
                vendeg={kivalasztottVendegReszekBeallitasahoz} 
                rendezveny={rendezveny}
            />
            */}

            {(jegyek != undefined && jegyek.length > 0) ? 
                <div className='vendegek_container'>
                    <div className='vendegek_cim'>Regisztrált vendégek</div>
                    {jegyek.map(function(jegy){
                        return(
                            <div className="vendegek_flex" key={"vendeg_" + jegy.fakeIndex}>
                                <div className="vendegek_inside">
                                    <FontAwesomeIcon icon={jegy.jegyTipus.Faiconcode} size='2x' color="#609090" />
                                </div>
                                <div className="vendegek_inside vendeg">
                                    <span className="bold">{jegy.Vendegneve}</span>
                                </div>
                                {(jegy.jegyTipus.Rendezvenyreszlatogato == 1) ?
                                    <div className="vendegek_inside">
                                        <b>Hangszerek kipróbálása</b>
                                        <br />
                                        <br />
                                        {(jegy.rendezvenyReszek.length < 2) ?
                                            <div>Legfeljebb 2 hangszer választható!<br /></div>
                                            :
                                            <div>
                                                {function(){
                                                    var o = [0, 1];
                                                    return o.map(function(i){
                                                        let resz = jegy.rendezvenyReszek[i];
                                                        
                                                        if (resz === undefined) {
                                                            // Ha nincs első kiválasztott rész
                                                            return (
                                                                <div key={'resz_'+i}>
                                                                    <b>{i+1}.</b>&nbsp;
                                                                    Nincs kiválasztott időpont
                                                                </div>
                                                            )
                                                        }else{
                                                            return (
                                                                <div key={'resz_'+i}>
                                                                        <b>{i+1}.</b>&nbsp;&nbsp;
                                                                        <b>{resz.Nev}&nbsp;&nbsp;</b>
                                                                        ({resz.Idokezdet.substring(0, 5)} - {resz.Idoveg.substring(0, 5)} - {resz.Helyszin})
                                                                </div>
                                                            )
                                                        }
                                                    })
                                                }()}
                                            </div>
                                        }

                                    </div>
                                    :
                                    undefined
                                }
                                <div className="vendegek_inside_button">
                                    {szerkesztheto ? 
                                        <Button variant="default" onClick={onClick_jegy_torlese.bind(this, jegy)}>Törlés</Button>
                                        :
                                        undefined
                                    }
                                </div>
                            </div>
                        )
                    })}
                </div>
                :
                undefined
            }


            {szerkesztheto ? 
                <div className="vendegek_container">
                    <br />
                    <div className="utasitas">
                        Az alábbi gombok használatával adja meg az érkező vendégek nevét!
                    </div>

                    {/*
                    <div className="utasitas-megjegyzes kisero">
                        A növendékként érkezőket és a felnőtt kísérőt is adja meg!<br />
                    </div>
                    
                    <br />
                    <div>A gyermekek regisztrációjához hangszer-kipróbálási lehetőség is jár.
                        <br />
                        A felnőtt kísérőknek csak a nevét kérjük.</div>
                    <br />
                    */}
                    <br />
                    
                        <div className="btnVendegHozzaadasaContainer">
                            {rendezveny.jegytipusok.map(function(jegyTipus){
                                if (elerte_a_maximumot_a_jegyek_szama_ebben_a_jegytipusban(jegyTipus)){return <div />}else{
                                    return(
                                    <div key={'jegytip_' + jegyTipus.Id} className="btnVendegHozzaadasa" onClick={onClick_jegy_hozzaadasa.bind(this, jegyTipus)}>
                                        <span className="icon"><FontAwesomeIcon icon={jegyTipus.Faiconcode} size='2x' color="#609090" /></span>
                                        <div>
                                            <div className="text-nagy">{jegyTipus.Gombfelirat}</div>
                                            {jegyTipus.Maxjegytipusegymegrendelesben != undefined ? 
                                                <div className="text-kicsi">Legfeljebb {jegyTipus.Maxjegytipusegymegrendelesben}</div>
                                                : undefined
                                            }
                                            <div className="text-kicsi" dangerouslySetInnerHTML={{__html: jegyTipus.Gombmegjegyzeshtml}} />
                                            
                                        </div>
                                    </div>    
                                    )
                                }
                            })}
                        </div>
                        <br clear="both" />
                </div>
            : undefined
        }

        </div>
    )
}